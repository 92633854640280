import Img from 'gatsby-image'
import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import AutoresJSON from '../../assets/data/autores.json'

import usePageQuery from '../../pageQuery'

import { BoxCarousel, SectionWrapper } from './style'

type DataProps = {
  foto: string;
  nome: string;
  area: string;
  descricao: string;
}

type CarrouselProps = {
  setOpenModal: Function;
  setDataLayer: Function;
}

function Carrousel ({ setOpenModal, setDataLayer }: CarrouselProps) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    element_action: 'click button',
    element_name: 'Quero receber',
    section_name: ' Newsletters preparadas por especialistas',
  }

  return (
    <SectionWrapper className='bg-gray py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-12 col-lg-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 text-left d-none d-lg-block text-lg-left'>
              Newsletters preparadas por especialistas
            </h2>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 text-left d-block d-lg-none text-md-center'>
              A  experiência que você encontra aqui
            </h2>
            <p className='fs-18 lh-22 fw-400 text-grayscale--500 d-none d-lg-block text-lg-left'>
              <span className='d-inline d-xl-block'>Nosso time de profissionais conta com </span>
              <span className='d-inline d-xl-block'>experiência e expertise de mercado para </span>
              <span className='d-inline d-xl-block'>trazer informações de investimento que </span>
              vão ajudar você no seu dia a dia.
            </p>
            <p className='fs-18 lh-22 fw-400 text-grayscale--500 text-md-center d-block d-lg-none text-md-center'>
              Conte com o time Inter Portfólios e tenha profissionais com as melhores informações
              para desenvolver seu patrimônio.
            </p>
          </div>

          <div className='col-12 col-lg-7'>
            <div>
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 1 }}
                lg={{ items: 1 }}
                xl={{ items: 1 }}
              >
                { AutoresJSON.map((item: DataProps) => (
                  <BoxCarousel key={item.nome}>
                    <div className='row align-items-center justify-content-center'>
                      <div className='col-8 col-md-4 col-lg-5 col-xl-4 text-center mb-4 mb-md-0'>
                        <Img fluid={data[item.foto].fluid} alt={item.nome} />
                      </div>
                      <div className='col-12 col-md-4 col-lg-7 col-xl-8'>
                        <div className='align-items-center ml-2 ml-md-0'>
                          <h4 className='fs-24 lh-30 fs-xl-32 lh-xl-40 fw-600 mb-lg-1 text-grayscale--500'>
                            {item.nome}
                          </h4>
                          <span className='d-block fs-20 lh-25 fs-xl-24 lh-xl-30 fw-400 text-grayscale--500 mb-2'>
                            {item.area}
                          </span>
                          <p className='fs-14 lh-17 fw-400 text-grayscale--500'>{item.descricao}</p>
                        </div>
                      </div>
                    </div>
                  </BoxCarousel>
                  ),
                )}
              </DefaultCarousel>
            </div>
            <div className='d-flex d-lg-none justify-content-center'>
              <button
                type='button' title='Começar a Investir'
                className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-5 text-md-center'
                onClick={() => {
                  setOpenModal(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Quero receber
              </button>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Carrousel
